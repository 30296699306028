import React, { useEffect, useState } from "react";
import { Outlet, useParams, useNavigate } from "react-router-dom";
import { server } from "../../config/server.js";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  MarkerF,
  InfoWindow,
  OverlayViewF,
  OverlayView,
  Circle,
} from "@react-google-maps/api";
import logistikMain from "../../assets/logistikMain.png";
import katalogPic1 from "../../assets/katalogPic1.png";
import katalogPic2 from "../../assets/katalogPic2.png";
export default function Katalog() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [headerImg, setHeaderImg] = useState([]);
  const [selectedCatalog, setSelectedKatalog] = useState(null);

  const containerStyle = {
    width: "100%",
    height: "100%",
    borderRadius: 30,
    zIndex: 0,
  };
  const getData = () => {
    fetch(`${server}/api/v1/product`, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      // mode: "cors", // no-cors, *cors, same-origin
      // body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then(async (res) => {
        console.log(res, "h");
        if (res.status) {
          const temp = res.data;

          for (let i = 0; i < temp.length; i++) {
            const element = temp[i];
            element.lng = Number(element.product_category_lng);
            element.lat =  Number(element.product_category_lat);
            console.log(element);
          }

          console.log(temp);
          setData(res.data ? temp : {});
         
          setHeaderImg(res.data[0] ? res.data[0].content_header_link : "");
        } else {
          throw res;
        }
      })

      .catch((err) => {
        console.log(err);
      });
  };
  const rupiahDelimiter = (nominal) => {
    let nominalString = String(nominal);
    let counter = 0;
    let result = "";
    for (let i = nominalString.length - 1; i >= 0; i--) {
      const element = nominalString[i];
      counter++;
      result += element;
      if (Number.isInteger(counter / 3) == true && i !== 0) {
        result += ".";
      }
    }
    return result.split("").reverse().join("");
  };
  const catalogData = [
    {
      name: "Industrial Land",
      lat: -6.359781324206519,
      lng: 106.85835560091753,
      item_lat: -6.359781324206519,
      item_lng: 106.85835560091753,
      desc: "ini Tanah Industri",
      items: [
        {
          name: "D-06-B",
          img: katalogPic1,
          sales: "Bima",
          price: 300000000,
          phone: "0809100100",
        },
        {
          name: "D-07-B",
          img: katalogPic2,
          sales: "Hefni",
          price: 300000000,
          phone: "0809100100",
        },
        {
          name: "D-06-B",
          img: katalogPic1,
          sales: "Bima",
          price: 300000000,
          phone: "0809100100",
        },
        {
          name: "D-06-B",
          img: katalogPic1,
          sales: "Bima",
          price: 300000000,
          phone: "0809100100",
        },
        {
          name: "D-06-B",
          img: katalogPic1,
          sales: "Bima",
          price: 300000000,
          phone: "0809100100",
        },
      ],
    },
    {
      name: "Business Center",
      lat: -6.359781324206519,
      lng: 106.79835560091753,
      item_lat: -6.359781324206519,
      item_lng: 106.79835560091753,
      desc: "ini Pusat Bisnis",
      items: [
        {
          name: "D-07-B",
          img: katalogPic2,
          sales: "Hefni",
          price: 300000000,
          phone: "0809100100",
        },
        {
          name: "D-06-B",
          img: katalogPic1,
          sales: "Bima",
          price: 300000000,
          phone: "0809100100",
        },
        {
          name: "D-07-B",
          img: katalogPic2,
          sales: "Hefni",
          price: 300000000,
          phone: "0809100100",
        },
      ],
    },
    {
      name: "Container Yard Cakung",
      lat: -6.359781324206519,
      lng: 106.95835560091753,
      item_lat: -6.359781324206519,
      item_lng: 106.95835560091753,
      desc: "Pusat Kontainer ama TREK",
      items: [
        {
          name: "D-06-B",
          img: katalogPic1,
          sales: "Bima",
          price: 300000000,
          phone: "0809100100",
        },
        {
          name: "D-07-B",
          img: katalogPic2,
          sales: "Hefni",
          price: 300000000,
          phone: "0809100100",
        },
      ],
    },
  ];
  const handleMarkerClick = async (coor, i) => {
    console.log(coor, "kOOR");
    if (map) {
      map.panTo(coor);
      setSelectedKatalog(i);
      document.getElementById("katalogListId").classList.add("katalogList");
      document.getElementById("katalogListId").style.opacity = "0 !important";
      document.getElementById("katalogListId").style.opacity = "1 !important";
    }
  };
  const handleMapSelect = (coor, i) => {
    map.panTo(coor);
    setSelectedKatalog(i);
    document.getElementById("katalogListId").classList.add("katalogList");
    document.getElementById("katalogListId").style.opacity = "0 !important";
    document.getElementById("katalogListId").style.opacity = "1 !important";
  };
  const [mapCenter, setMapCenter] = useState({
    lat: -6.359781324206519,
    lng: 106.85835560091753,
  });

  const center = {
    lat: -6.359781324206519,
    lng: 106.85835560091753,
  };
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyB0xhAB2rpMeYxWGrQUMUgSt0m6hMNdUB4",
  });
  const [infoWindow, setInfoWindow] = useState(null);

  const handleLeave = () => {
    setInfoWindow(null);
  };

  const handleInfoWindowClose = () => {
    setInfoWindow(null);
  };
  const [map, setMap] = React.useState(null);
  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);
  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(center);
    map.setZoom(12);

    setMap(map);
  }, []);
  useEffect(() => {
    // console.log("dsd", infoWindow, map.getZoom());
    getData();
    window.scrollTo(0, 0);
  }, [selectedCatalog]);
  const okeGas = () => {
    return <div style={{ width: 20, height: 20, background: "red" }}></div>;
  };
  function Select({ handleMapSelect, catalogData }) {
    return (
      <div>
        <select
          class="form-select katalogMapSelect mx-auto py-2 "
          style={{ borderRadius: 20 }}
          aria-label="Default select example"
          onChange={(e) => {
            handleMapSelect(
              JSON.parse(e.target.value).coordinate,
              JSON.parse(e.target.value).index
            );
            console.log(JSON.parse(e.target.value).index);
            // setSelectedKatalog(JSON.parse(e.target.value).index);
            // map.panTo(JSON.parse(e.target.value).coordinate);
          }}
        >
          <option disabled value="" selected>
            Select category
          </option>
          {data.map((item, i) => {
            return (
              <option
                value={JSON.stringify({
                  coordinate: { lat: item.lat, lng: item.lng },
                  index: i,
                })}
              >
                {item.product_category_name}
              </option>
            );
          })}
        </select>
      </div>
    );
  }
  return (
    <>
    {/* {JSON.stringify(data)} */}
     {data.length >0? <> <Outlet />
      <div class="katalogWrapper flex block1200 w100">
        <div class="w100 mapWrapper">
          {isLoaded ? (
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={mapCenter}
              zoom={12}
              onLoad={onLoad}
              onUnmount={onUnmount}
              className={"gmap-component"}
              class={"gemap-map-component"}
            >
              {/* {infoWindow && (
            <InfoWindow
              position={{
                lat: -6.349781324206519,
                lng: 106.85835560091753,
              }}
              onCloseClick={handleInfoWindowClose}
              onMouseLeave={(e) => {
                setInfoWindow(null);
              }}
            >
              <div>
                <h2>Markeddr Label</h2>
                <p>This is the label content.</p>
              </div>
            </InfoWindow>
          )} */}

              <Select
                handleMapSelect={handleMapSelect}
                catalogData={catalogData}
              />

              {data.map((item, i) => {
                return (
                  <MarkerF
                    //  onClick={handleMarkerClick}
                    onMouseOver={(e) => {
                      setInfoWindow(i);
                    }}
                    onMouseOut={handleLeave}
                    onClick={(e) => {
                      handleMarkerClick({ lng: item.lng, lat: item.lat }, i);
                    }}
                    icon={okeGas}
                    label={String(item.product.length)}
                    position={{ lat: item.lat, lng: item.lng }}
                  >
                    {infoWindow == i ? (
                      <InfoWindow
                        onCloseClick={handleInfoWindowClose}
                        onMouseLeave={(e) => {
                          setInfoWindow(null);
                        }}
                      >
                        <div>
                          <h5>{item.product_category_name}</h5>
                          <p>{item.product_category_desc}</p>
                        </div>
                      </InfoWindow>
                    ) : null}
                  </MarkerF>
                );
              })}

              {/* <MarkerF
                //  onClick={handleMarkerClick}
                onMouseOver={handleMarkerClick}
                onMouseOut={handleLeave}
                icon={okeGas}
                label={"1"}
                position={{ lat: -6.359781324206519, lng: 106.85835560091753 }}
              >
                {infoWindow && (
                  <InfoWindow
                    onCloseClick={handleInfoWindowClose}
                    onMouseLeave={(e) => {
                      setInfoWindow(null);
                    }}
                  >
                    <div>
                      <h2>Markeddr Label</h2>
                      <p>This is the label content.</p>
                    </div>
                  </InfoWindow>
                )}
              </MarkerF> */}

              {/* Child components, such as MarkerF s, info windows, etc. */}
              <></>
            </GoogleMap>
          ) : (
            <></>
          )}
        </div>
        <div id="katalogListId" class="w100">
          <div class="px-5 w100">
            {selectedCatalog !== null ? (
              <>
                {/* <div
                  class="pageText mb-3"
                  style={{ fontSize: "2rem", textAlign: "center" }}
                >
                  List - {catalogData[selectedCatalog].name}
                </div> */}

                {data[selectedCatalog].product.map((item, i) => {
                  return (
                    <div
                      onClick={(e) => {
                        navigate(`/katalog/${item.product_id}`);
                      }}
                      class="w100 flex katalogItem mb-4 py-4 px-4 pointer"
                    >
                      <div
                        class="w50"
                        id={`list${i}`}
                        onLoad={(e) => {
                          console.log("loded");
                          document
                            .getElementById(`list${i}`)
                            .classList.add("o1");
                        }}
                        style={{
                          backgroundImage: `url(${item.product_thumbnail_link})`,
                          display: "inline-block",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          cursor: "pointer",
                        }}
                      ></div>
                      <div class="w50  px-4">
                        <div class="listName pageText greenText">
                          {item.name}
                        </div>
                        <div class="listPrice pageText greenText">
                          Rp. {rupiahDelimiter(item.price)}
                        </div>
                        <table
                          style={{ fontSize: 20 }}
                          class="table  katalogByIdDescTable  pageText"
                        >
                          <tbody>
                            <tr>
                              <td>
                                <div style={{ paddingRight: "2rem" }}>
                                  {item.sales_name}
                                </div>
                              </td>

                              <td></td>
                            </tr>
                            <tr>
                              <td>
                                <div style={{ paddingRight: "2rem" }}>
                                  Contact
                                </div>
                              </td>

                              <td>: {item.sales_phone}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  );
                })}
              </>
            ) : null}
          </div>
        </div>
      </div>
      {/* {JSON.stringify(data[0])} */}
      {/* {data.map((item, i) => {
        return (
          <>
            <div>{JSON.stringify(item.lng)}</div>
          </>
        );
      })} */}
      </>:null}
    </>
  );
}


