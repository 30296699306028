import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Lightbox from "yet-another-react-lightbox";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import { server } from "../../config/server.js";

export default function KatalogById() {
  const { id } = useParams();
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);
  const [productData, setProductData] = useState(null);

  useEffect(() => {
    getData();
    window.scrollTo(0, 0);
  }, [id]);

  const getData = () => {
    fetch(`${server}/api/v1/productDetail/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status) {
          // console.log(res, "fasfsf");
          setProductData(res.data[0]);
        } else {
          throw new Error(res.message);
        }
      })
      .catch((err) => {
        console.log(err);
        // Handle error (e.g., redirect to error page or show error message)
      });
  };
  const rupiahDelimiter = (nominal) => {
    let nominalString = String(nominal);
    let counter = 0;
    let result = "";
    for (let i = nominalString.length - 1; i >= 0; i--) {
      const element = nominalString[i];
      counter++;
      result += element;
      if (Number.isInteger(counter / 3) == true && i !== 0) {
        result += ".";
      }
    }
    return result.split("").reverse().join("");
  };
  const handleLightbox = (i) => {
    setSelectedImage(i);
    setLightboxOpen(true);
  };

  if (!productData) {
    return null;
  }

  return (
    <>
      <div
        className="pageMainImg"
        style={{ backgroundImage: `url(${productData.product_thumbnail_link})` }}
      ></div>
      <Lightbox
        open={lightboxOpen}
        close={() => setLightboxOpen(false)}
        slides={productData.product_file.map((item) => ({ src: item.public_link }))}
        plugins={[Thumbnails]}
        index={selectedImage}
      />
      {/* {JSON.stringify(productData)} */}
      <div className="flex py-4 block1000">
        <div className="katalogByIdDesc flex pageText mx-auto">
          <table className="table katalogByIdDescTable my-4">
            <tbody>
              <tr>
                <td>
                  <div style={{ paddingRight: "2rem" }}>Kategori</div>
                </td>
                <td>: {productData.product_category_name}</td>
              </tr>
              <div>
                <p>
                  <div
                    class="py-4"
                    dangerouslySetInnerHTML={{
                      __html: productData.product_content,
                    }}
                  ></div>
                </p>
              </div>

              {/* <td colSpan="2">
                  <div style={{ paddingRight: "2rem" }}>
                    Specs
                    <div
                      dangerouslySetInnerHTML={{
                        __html: productData.product_content,
                      }}
                    ></div>
                  </div>
                </td> */}

              <tr>
                <td>
                  <div style={{ paddingRight: "2rem" }}>Harga</div>
                </td>
                <td>: Rp. {rupiahDelimiter(productData.price)}</td>
              </tr>

              <tr>
                <td>
                  <div style={{ paddingRight: "2rem" }}>Status</div>
                </td>
                <td>: {productData.product_thumbnail_path}</td>
              </tr>
              <tr>
                <td>
                  <div style={{ paddingRight: "2rem" }}>Email</div>
                </td>
                <td>: {productData.sales_email}</td>
              </tr>
              <tr>
                <td>
                  <div style={{ paddingRight: "2rem" }}>Handphone</div>
                </td>
                <td>: {productData.sales_phone}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          className="katalogByIdGalleryContainer"
          style={{
            overflowX: "auto",
            overflowY: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          {productData.product_file.map((item, i) => (
            <div
              key={i}
              className="mx-3 my-4 detailProdImg"
              style={{
                height: 310,
                borderRadius: 20,
                width: 270,
                backgroundImage: `url(${item.public_link})`,
                display: "inline-block",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
                cursor: "pointer",
              }}
              onClick={() => handleLightbox(i)}
            ></div>
          ))}
        </div>
      </div>
    </>
  );
}
