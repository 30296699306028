import React,{useEffect} from "react";
import { Outlet } from "react-router-dom";

export default function PageIndex() {
  useEffect(() => {
    console.log('dsd');
    window.scrollTo(0, 0);
  }, []);
  return (
    <div class="py-5 upperHomeContainer mx-auto subMenuWrapper" style={{minHeight:'70vh'}}>
      <Outlet />
    </div>
  );
}
