import logistikMain from "../../assets/logistikMain.png";
import akhlak from "../../assets/akhlak.png";
import ViewCount from "../../components/ViewCount.js";
import React,{useEffect} from "react";
export default function VisiMisi() {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <>
      <div
        class="pageMainImg"
        style={{ backgroundImage: `url(${logistikMain})` }}
      ></div>
      <div class="pageTitle">RKAP</div>
      <div class="articleContainer pageText">
      <div class="list image">
            
            <p><b>LAPORAN RKAP</b></p><p><a href="http://kbn.co.id/2020/RKAP/RKAP KBN 2021.pdf" target="_blank">RKAP 2021</a></p>            
     
  </div>

        <ViewCount count={77} />
      </div>
    </>
  );
}
