import React, { useEffect, useState } from "react";
import { server } from "../../config/server.js";
import ViewCount from "../../components/ViewCount.js";
import logistikMain from "../../assets/logistikMain.png";

export default function Manajemen() {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(38); // Initialize with default page
  const [subContent, setSubContent] = useState(null);

  useEffect(() => {
    getData();
    window.scrollTo(0, 0);
  }, []);

  const getData = () => {
    fetch(`${server}/api/v1/content/list/13/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setData(res.data);
        setSubContent(res.data[0]?.SubContent || []);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const backgroundImage = data.length > 0 ? data[0].content_header_link.replace(/ /g, "%20") : null;

  const content = () => {
    const currentContent = subContent ? subContent.find(subItem => subItem.content_type_id === page) : null;
    return (
      <>
        <div className="pageTitle">{currentContent ? currentContent.content_title : "Loading..."}</div>
        <div className="articleContainer pageText">
          <div className="list image" style={{ textAlign: "justify" }}>
            {currentContent && currentContent.content_html ? (
              <div dangerouslySetInnerHTML={{ __html: currentContent.content_html }}></div>
            ) : <div>No Content Available</div>}
          </div>
          {/* <ViewCount count={currentContent ? currentContent.views : 0} /> */}
        </div>
      </>
    );
  };

  return (
    <>
      <img class="w100" style={{ borderRadius: 20 }} src={backgroundImage}></img>
      <div className="articleContainer pageText">
        <div className="list image">
          <div className="flex" style={{ gap: 4 }}>
            {subContent &&
              subContent.map((subItem) => (
                <div
                  key={subItem.content_type_id}
                  className={`tabMenu ${page === subItem.content_type_id ? "tabActive" : ""}`}
                  onClick={() => setPage(subItem.content_type_id)}
                >
                  <div className="tabName px-4 py-2">{subItem.content_title}</div>
                  <div className="tabUnderline"></div>
                </div>
              ))}
          </div>
          {content()}
        </div>
        <ViewCount count={data.length > 0 ? data[0].views : 0} />
      </div>
    </>
  );
}
