import React, { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { server } from "../../config/server.js";
import ViewCount from "../../components/ViewCount.js";
import Lightbox from "yet-another-react-lightbox";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import logistikMain from "../../assets/logistikMain.png";

export default function Berita() {
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);
  const [newsList, setNewsList] = useState([]);
  const [data, setData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    getData();
    getNews();
    window.scrollTo(0, 0);
  }, []);

  const getData = async () => {
    try {
      const response = await fetch(`${server}/api/v1/content/list/32/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();
      if (result.status) {
        setData(result.data[0] || {});
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (err) {
      console.log("Error fetching data:", err);
    }
  };

  const getNews = () => {
    fetch(`${server}/api/v1/news/list`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status) {

          setNewsList(res.data || []);
        } else {
          throw new Error("Failed to fetch news");
        }
      })
      .catch((err) => {
        console.log("Error fetching news:", err);
      });
  };

  const handleNewsClick = (news) => {
    navigate(`/media/berita/${news.news_id}`, {
      state: { news: news },
    });
  };

  const backgroundImage = data ? data.content_header_link:null

  return (
    <>
      <Lightbox
        open={lightboxOpen}
        close={() => setLightboxOpen(false)}
        slides={newsList.map((news) => ({
          src: news.images[0]?.public_link || "",
        }))}
        plugins={[Thumbnails]}
        index={selectedImage}
      />
{/* {JSON.stringify(data.content_header_link)} */}
<img class="w100" style={{ borderRadius: 20 }} src={backgroundImage}></img>
      <div className="pageTitle">{data.content_title || "Loading..."}</div>

      <div className="articleContainer pageText">
        <div className="list image">
          <div className="w100 fotoCardContainer">
            {newsList.map((news, index) => (
              <div
                key={index}
                className="newsCard mx-4 mb-5 pointer"
                onClick={() => handleNewsClick(news)}
              >
                <div
                  className="newsThumbnail"
                  style={{
                    backgroundImage: `url("${news.images[0]?.public_link || ""}")`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    cursor: "pointer",
                  }}
                ></div>
                <div className="newsName greenText pageText greenText">
                  {news.news_title}
                </div>
                <div
                  style={{
                    fontSize: 18,
                    color: "#808080",
                    fontWeight: 400,
                  }}
                  className="pb-2"
                >
                  {moment(news.news_entry_date).locale("id").format("DD MMM YYYY")}
                </div>
                <div
                  className="newsIntro"
                  dangerouslySetInnerHTML={{ __html: news.content }}
                ></div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="paginationContainer w100">
        {/* Pagination component or logic goes here */}
      </div>

      <ViewCount count={data.views || 0} />
    </>
  );
}
