import React, { useEffect, useState } from "react";
import { server } from "../../config/server.js";
import ViewCount from "../../components/ViewCount.js";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import logistikMain from "../../assets/logistikMain.png";

export default function Video() {
  const [album, setAlbum] = useState([]);
  const [header, setHeader] = useState({});
  const MySwal = withReactContent(Swal);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const [headerResponse, albumResponse] = await Promise.all([
        fetch(`${server}/api/v1/content/list/45/`),
        fetch(`${server}/api/v1/content/detail/5/`),
      ]);

      const [headerResult, albumResult] = await Promise.all([
        headerResponse.json(),
        albumResponse.json(),
      ]);

      if (headerResult.status) {
        setHeader(headerResult.data[0] || {});
      } else {
        throw new Error("Failed to fetch header data");
      }

      if (albumResult.status) {
        const formattedAlbum = formatAlbumData(albumResult.data);
        setAlbum(formattedAlbum);
      } else {
        throw new Error("Failed to fetch album data");
      }
    } catch (err) {
      console.log("Error fetching data:", err);
    }
  };

  const formatAlbumData = (data) => {
    return data.map((album) => {
      return {
        ...album,
        videoUrl: album.content_url, // Assuming content_url contains the YouTube embed link
      };
    });
  };


  const handleAlbumClick = (videoUrl, title) => {
    MySwal.fire({
      html: (
        <div>
          <div className="w100 py-4 modalTitle pageText" style={{ fontWeight: 600, color: "#005f3b" }}>
            {title}
            <button
              className="btn"
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                background: "transparent",
                border: "none",
                cursor: "pointer",
                fontSize: "24px",
                color: "#005F3B",
              }}
              onClick={() => MySwal.close()}
            >
              &times;
            </button>
          </div>
          <div className="center-vertical mx-auto text-center popupFotoContainer" style={{ minHeight: "50vh" }}>
            <iframe
              width="560"
              height="315"
              src={videoUrl}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      ),
      width: "fit-content",
      height: "fit-content",
      customClass: "galleryModal",
      allowOutsideClick: false,
      showClass: {
        backdrop: "swal2-noanimation",
        popup: "animated fadeInDown faster",
        icon: "animated heartBeat delay-1s",
      },
      showConfirmButton: false,
      heightAuto: false,
    });
  };

  const backgroundImage = header.content_header_link ? header.content_header_link.replace(/ /g, "%20") : null;
  const contentTitle = header.content_title || "";
  const contentViews = header.views || "0";

  return (
    <>
      <img class="w100" style={{ borderRadius: 20 }} src={backgroundImage}></img>
      <div className="pageTitle">{contentTitle}</div>
      <div className="articleContainer pageText">
        <div className="list image">
          <div className="w100 fotoCardContainer">
            {album.map((item, index) => (
              <div
                key={index}
                className="fotoCard mx-3 mb-4"
                onClick={() => handleAlbumClick(item.videoUrl, item.content_title)}
              >
                <div
                  className="albumThumbnail"
                  style={{
                    backgroundImage: `url("https://img.youtube.com/vi/${getYouTubeVideoID(item.videoUrl)}/hqdefault.jpg")`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    cursor: "pointer",
                    backgroundColor: "transparent",
                  }}
                ></div>
                <div className="cardName greenText center-vertical pageText greenText">{item.content_title}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <ViewCount count={contentViews} />
    </>
  );
}

function getYouTubeVideoID(url) {
  if (!url) return null; // Handle case where url is undefined or null

  const regExp = /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/|youtube\.com\/embed\/)([^"&?\/\s]{11})/i;
  const match = url.match(regExp);
  return match ? match[1] : null;
}
