import logistikMain from "../../assets/logistikMain.png";
import prop1 from "../../assets/prop1.jpg";
import prop2 from "../../assets/prop2.jpg";
import prop3 from "../../assets/prop3.jpg";
import prop4 from "../../assets/prop4.jpg";
import ViewCount from "../../components/ViewCount.js";
import React, { useEffect, useState } from "react";
import "react-image-lightbox/style.css";
import Lightbox from "yet-another-react-lightbox";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate, useParams } from "react-router-dom";
export default function BeritaById() {
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);
  const [selectedAlbum, setSelectedAlbum] = useState();
  const navigate = useNavigate();
  const { id } = useParams();
  const album = [
    { src: prop1 },
    { src: prop2 },
    { src: prop3 },
    { src: prop1 },
    { src: prop2 },
    { src: prop3 },
    { src: prop1 },
    { src: prop2 },
    { src: prop3 },
    { src: prop1 },
    { src: prop2 },
    { src: prop3 },
  ];

  const MySwal = withReactContent(Swal);
  useEffect(() => {
    console.log("babay");
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (selectedAlbum !== undefined) {
      // return galleryModal()
    }
  }, [selectedAlbum]);
  const images = [
    { src: prop1 },
    { src: prop2 },
    { src: prop3 },
    { src: prop4 },
  ];

  const allNews = [
    {
      name: "PT KBN Meresmikan Fasilitas MCK di Kampung Sawah Semper Timur Cilincing",
      id: 1,
      img: prop1,
      content: `"PT Kawasan Berikat Nusantara (Persero) atau PT KBN (Persero) adalah bagian dari Badan Usaha Milik Daerah Pemprov DKI Jakarta. Berkutat di bidang usaha pengelola lokasi kawasan industri yang strategis di Jakarta.

Cikal bakal pendirian PT KBN bermula saat Pemerintah Republik Indonesia mendirikan PT. Yado Warehousing (Persero) pada 1968. Perusahaan yang menempati lahan seluas empat hektar di Kampung Bandan, Jakarta Utara ini mempunyai tugas untuk mengelola kawasan pergudangan sebagai pintu gerbang atau entreport umum untuk impor.

Sepanjang perjalanannya, PT. KBN terus berkembang seiring dinamika pertumbuhan ekonomi Indonesia maupun ekonomi global dalam mengelola kawasan industri. Selain itu, perusahaan juga semakin besar berkat adanya penggabungan dari BUMN lain yang dilikuidasi dan bergabung dalam PT KBN.

PT KBN yang berdiri pada 28 Juni 1986, hingga kini formasi kepemilikan saham PT KBN dimiliki Danareksa sebanyak 73.1%, milik Pemprov DKI Jakarta sebanyak 26,8% dan 0.02% milik Pemerintah Pusat"`,
    },
    {
      name: "PT KBN Meresmikan Fasilitas MCK di Kampung Sawah Semper Timur Cilincing",
      id: 1,
      img: prop4,
      content: `"PT Kawasan Berikat Nusantara (Persero) atau PT KBN (Persero) adalah bagian dari Badan Usaha Milik Daerah Pemprov DKI Jakarta. Berkutat di bidang usaha pengelola lokasi kawasan industri yang strategis di Jakarta.

Cikal bakal pendirian PT KBN bermula saat Pemerintah Republik Indonesia mendirikan PT. Yado Warehousing (Persero) pada 1968. Perusahaan yang menempati lahan seluas empat hektar di Kampung Bandan, Jakarta Utara ini mempunyai tugas untuk mengelola kawasan pergudangan sebagai pintu gerbang atau entreport umum untuk impor.

Sepanjang perjalanannya, PT. KBN terus berkembang seiring dinamika pertumbuhan ekonomi Indonesia maupun ekonomi global dalam mengelola kawasan industri. Selain itu, perusahaan juga semakin besar berkat adanya penggabungan dari BUMN lain yang dilikuidasi dan bergabung dalam PT KBN.

PT KBN yang berdiri pada 28 Juni 1986, hingga kini formasi kepemilikan saham PT KBN dimiliki Danareksa sebanyak 73.1%, milik Pemprov DKI Jakarta sebanyak 26,8% dan 0.02% milik Pemerintah Pusat"`,
    },
    {
      name: "PT KBN Meresmikan Fasilitas MCK di Kampung Sawah Semper Timur Cilincing",
      id: 1,
      img: prop2,
      content: `"PT Kawasan Berikat Nusantara (Persero) atau PT KBN (Persero) adalah bagian dari Badan Usaha Milik Daerah Pemprov DKI Jakarta. Berkutat di bidang usaha pengelola lokasi kawasan industri yang strategis di Jakarta.

Cikal bakal pendirian PT KBN bermula saat Pemerintah Republik Indonesia mendirikan PT. Yado Warehousing (Persero) pada 1968. Perusahaan yang menempati lahan seluas empat hektar di Kampung Bandan, Jakarta Utara ini mempunyai tugas untuk mengelola kawasan pergudangan sebagai pintu gerbang atau entreport umum untuk impor.

Sepanjang perjalanannya, PT. KBN terus berkembang seiring dinamika pertumbuhan ekonomi Indonesia maupun ekonomi global dalam mengelola kawasan industri. Selain itu, perusahaan juga semakin besar berkat adanya penggabungan dari BUMN lain yang dilikuidasi dan bergabung dalam PT KBN.

PT KBN yang berdiri pada 28 Juni 1986, hingga kini formasi kepemilikan saham PT KBN dimiliki Danareksa sebanyak 73.1%, milik Pemprov DKI Jakarta sebanyak 26,8% dan 0.02% milik Pemerintah Pusat"`,
    },
    {
      name: "PT KBN Meresmikan Fasilitas MCK di Kampung Sawah Semper Timur Cilincing",
      id: 1,
      img: prop3,
      content: `"PT Kawasan Berikat Nusantara (Persero) atau PT KBN (Persero) adalah bagian dari Badan Usaha Milik Daerah Pemprov DKI Jakarta. Berkutat di bidang usaha pengelola lokasi kawasan industri yang strategis di Jakarta.

Cikal bakal pendirian PT KBN bermula saat Pemerintah Republik Indonesia mendirikan PT. Yado Warehousing (Persero) pada 1968. Perusahaan yang menempati lahan seluas empat hektar di Kampung Bandan, Jakarta Utara ini mempunyai tugas untuk mengelola kawasan pergudangan sebagai pintu gerbang atau entreport umum untuk impor.

Sepanjang perjalanannya, PT. KBN terus berkembang seiring dinamika pertumbuhan ekonomi Indonesia maupun ekonomi global dalam mengelola kawasan industri. Selain itu, perusahaan juga semakin besar berkat adanya penggabungan dari BUMN lain yang dilikuidasi dan bergabung dalam PT KBN.

PT KBN yang berdiri pada 28 Juni 1986, hingga kini formasi kepemilikan saham PT KBN dimiliki Danareksa sebanyak 73.1%, milik Pemprov DKI Jakarta sebanyak 26,8% dan 0.02% milik Pemerintah Pusat"`,
    },
    {
      name: "PT KBN Meresmikan Fasilitas MCK di Kampung Sawah Semper Timur Cilincing",
      id: 1,
      img: prop1,
      content: `"PT Kawasan Berikat Nusantara (Persero) atau PT KBN (Persero) adalah bagian dari Badan Usaha Milik Daerah Pemprov DKI Jakarta. Berkutat di bidang usaha pengelola lokasi kawasan industri yang strategis di Jakarta.

Cikal bakal pendirian PT KBN bermula saat Pemerintah Republik Indonesia mendirikan PT. Yado Warehousing (Persero) pada 1968. Perusahaan yang menempati lahan seluas empat hektar di Kampung Bandan, Jakarta Utara ini mempunyai tugas untuk mengelola kawasan pergudangan sebagai pintu gerbang atau entreport umum untuk impor.

Sepanjang perjalanannya, PT. KBN terus berkembang seiring dinamika pertumbuhan ekonomi Indonesia maupun ekonomi global dalam mengelola kawasan industri. Selain itu, perusahaan juga semakin besar berkat adanya penggabungan dari BUMN lain yang dilikuidasi dan bergabung dalam PT KBN.

PT KBN yang berdiri pada 28 Juni 1986, hingga kini formasi kepemilikan saham PT KBN dimiliki Danareksa sebanyak 73.1%, milik Pemprov DKI Jakarta sebanyak 26,8% dan 0.02% milik Pemerintah Pusat"`,
    },
    {
      name: "PT KBN Meresmikan Fasilitas MCK di Kampung Sawah Semper Timur Cilincing",
      id: 1,
      img: prop4,
      content: `"PT Kawasan Berikat Nusantara (Persero) atau PT KBN (Persero) adalah bagian dari Badan Usaha Milik Daerah Pemprov DKI Jakarta. Berkutat di bidang usaha pengelola lokasi kawasan industri yang strategis di Jakarta.

Cikal bakal pendirian PT KBN bermula saat Pemerintah Republik Indonesia mendirikan PT. Yado Warehousing (Persero) pada 1968. Perusahaan yang menempati lahan seluas empat hektar di Kampung Bandan, Jakarta Utara ini mempunyai tugas untuk mengelola kawasan pergudangan sebagai pintu gerbang atau entreport umum untuk impor.

Sepanjang perjalanannya, PT. KBN terus berkembang seiring dinamika pertumbuhan ekonomi Indonesia maupun ekonomi global dalam mengelola kawasan industri. Selain itu, perusahaan juga semakin besar berkat adanya penggabungan dari BUMN lain yang dilikuidasi dan bergabung dalam PT KBN.

PT KBN yang berdiri pada 28 Juni 1986, hingga kini formasi kepemilikan saham PT KBN dimiliki Danareksa sebanyak 73.1%, milik Pemprov DKI Jakarta sebanyak 26,8% dan 0.02% milik Pemerintah Pusat"`,
    },
    {
      name: "PT KBN Meresmikan Fasilitas MCK di Kampung Sawah Semper Timur Cilincing",
      id: 1,
      img: prop3,
      content: `"PT Kawasan Berikat Nusantara (Persero) atau PT KBN (Persero) adalah bagian dari Badan Usaha Milik Daerah Pemprov DKI Jakarta. Berkutat di bidang usaha pengelola lokasi kawasan industri yang strategis di Jakarta.

Cikal bakal pendirian PT KBN bermula saat Pemerintah Republik Indonesia mendirikan PT. Yado Warehousing (Persero) pada 1968. Perusahaan yang menempati lahan seluas empat hektar di Kampung Bandan, Jakarta Utara ini mempunyai tugas untuk mengelola kawasan pergudangan sebagai pintu gerbang atau entreport umum untuk impor.

Sepanjang perjalanannya, PT. KBN terus berkembang seiring dinamika pertumbuhan ekonomi Indonesia maupun ekonomi global dalam mengelola kawasan industri. Selain itu, perusahaan juga semakin besar berkat adanya penggabungan dari BUMN lain yang dilikuidasi dan bergabung dalam PT KBN.

PT KBN yang berdiri pada 28 Juni 1986, hingga kini formasi kepemilikan saham PT KBN dimiliki Danareksa sebanyak 73.1%, milik Pemprov DKI Jakarta sebanyak 26,8% dan 0.02% milik Pemerintah Pusat"`,
    },
    {
      name: "PT KBN Meresmikan Fasilitas MCK di Kampung Sawah Semper Timur Cilincing",
      id: 1,
      img: prop2,
      content: `"PT Kawasan Berikat Nusantara (Persero) atau PT KBN (Persero) adalah bagian dari Badan Usaha Milik Daerah Pemprov DKI Jakarta. Berkutat di bidang usaha pengelola lokasi kawasan industri yang strategis di Jakarta.

Cikal bakal pendirian PT KBN bermula saat Pemerintah Republik Indonesia mendirikan PT. Yado Warehousing (Persero) pada 1968. Perusahaan yang menempati lahan seluas empat hektar di Kampung Bandan, Jakarta Utara ini mempunyai tugas untuk mengelola kawasan pergudangan sebagai pintu gerbang atau entreport umum untuk impor.

Sepanjang perjalanannya, PT. KBN terus berkembang seiring dinamika pertumbuhan ekonomi Indonesia maupun ekonomi global dalam mengelola kawasan industri. Selain itu, perusahaan juga semakin besar berkat adanya penggabungan dari BUMN lain yang dilikuidasi dan bergabung dalam PT KBN.

PT KBN yang berdiri pada 28 Juni 1986, hingga kini formasi kepemilikan saham PT KBN dimiliki Danareksa sebanyak 73.1%, milik Pemprov DKI Jakarta sebanyak 26,8% dan 0.02% milik Pemerintah Pusat"`,
    },
  ];
  const data = [
    {
      media:
        "https://money.kompas.com/read/2020/10/21/124620426/pansus-kbn-tinjau-pelabuhan-marunda-kcn-menunggu-rekomendasi-terbaik",
      title:
        "Bukit Asam, Kawasan Berikat Nusantara, Brantas Abipraya Selenggarakan BUMN untuk Negeri",
      id: 0,
      date: "28 Januari 2021, Kamis 04:03",
      ket: "Media Online",
    },
    {
      media:
        "https://money.kompas.com/read/2020/10/21/124620426/pansus-kbn-tinjau-pelabuhan-marunda-kcn-menunggu-rekomendasi-terbaik",
      title:
        "Bukit Asam, Kawasan Berikat Nusantara, Brantas Abipraya Selenggarakan BUMN untuk Negeri",
      id: 1,
      date: "28 Januari 2021, Kamis 04:03",
      ket: "Media Online",
    },
    {
      media:
        "https://money.kompas.com/read/2020/10/21/124620426/pansus-kbn-tinjau-pelabuhan-marunda-kcn-menunggu-rekomendasi-terbaik",
      title:
        "Bukit Asam, Kawasan Berikat Nusantara, Brantas Abipraya Selenggarakan BUMN untuk Negeri",
      id: 2,
      date: "28 Januari 2021, Kamis 04:03",
      ket: "Media Online",
    },
    {
      media:
        "https://money.kompas.com/read/2020/10/21/124620426/pansus-kbn-tinjau-pelabuhan-marunda-kcn-menunggu-rekomendasi-terbaik",
      title:
        "Bukit Asam, Kawasan Berikat Nusantara, Brantas Abipraya Selenggarakan BUMN untuk Negeri",
      id: 3,
      date: "28 Januari 2021, Kamis 04:03",
      ket: "Media Online",
    },
    {
      media:
        "https://money.kompas.com/read/2020/10/21/124620426/pansus-kbn-tinjau-pelabuhan-marunda-kcn-menunggu-rekomendasi-terbaik",
      title:
        "Bukit Asam, Kawasan Berikat Nusantara, Brantas Abipraya Selenggarakan BUMN untuk Negeri",
      id: 4,
      date: "28 Januari 2021, Kamis 04:03",
      ket: "Media Online",
    },
  ];
  const handleLighbox = async (i) => {
    await console.log(i);
    await setSelectedImage(i);
    await console.log(selectedImage);
    setLightboxOpen(true);
  };

  return (
    <>
      <Lightbox
        open={lightboxOpen}
        close={() => setLightboxOpen(false)}
        slides={selectedAlbum}
        plugins={[Thumbnails]}
        index={selectedImage}
      />
      <div
        class="pageMainImg"
        style={{ backgroundImage: `url(${prop1})` }}
      ></div>
       <div class="pageTitle">Kliping</div>
      <div class="articleContainer pageText">
        <div class="list image">
          <div class="flex ">
            <div class="" style={{paddingRight:'3rem'}}>
              <div>Hari/Tanggal</div>
              <div>Nama Media</div>
              <div>Halaman/Sumber</div>
            </div>
            <div>
              <div>: {data[id].date}</div>
              <div>: {data[id].ket}</div>
              <div>: {data[id].media}</div>
            </div>
          </div>
          <div
        class="pageTitle"
        style={{
          fontSize: 34,
          textAlign: "center",
        }}
      >
        {data[id].title}
      </div>
          <div class="w100 fotoCardContainer justify-content-around">
            {allNews[id].content}
          </div>
        </div>
      </div>
    </>
  );
}
const galleryModal = (selectedAlbum, setLightboxOpen, setSelectedImage) => {
  const MySwal = withReactContent(Swal);
  let modal = MySwal.mixin({
    // toast: true,
    // icon:'success',
    // backdrop: false,
    width: "fit-content",
    height: "fit-content",
    // backdrop: false,
    customClass: "galleryModal",
    className: "galleryModal",
    allowOutsideClick: false,
    showClass: {
      backdrop: "swal2-noanimation", // disable backdrop animation
      popup: "animated fadeInDown faster",
      icon: "animated heartBeat delay-1s",
    },
    html: (
      <div>
        <div
          class="w100 mb-3 modalTitle pageText"
          style={{
            fontWeight: 600,
            color: "#005f3b",
          }}
        >
          {selectedAlbum.name}
        </div>
        <div
          style={{
            minHeight: "50vh",
          }}
          class="center-vertical mx-auto text-center"
        >
          {selectedAlbum.items.map((img, i) => {
            return (
              <img
                onClick={(e) => {
                  setLightboxOpen(true);
                  setSelectedImage(i);
                }}
                class="galleryImg px-2 py-2"
                src={img.src}
              ></img>
            );
          })}
        </div>
        <button
          class="btn mx-3 px-5"
          style={{
            borderRadius: "50rem",
            fontFamily: "Roboto",
            fontSize: 18,
            border: "1px solid #005F3B",
            background: "#005F3B",
            color: "white",
          }}
          onClick={(e) => {
            MySwal.close();
          }}
        >
          Back
        </button>
      </div>
    ),
    showConfirmButton: false,
    heightAuto: false,
  });
  return modal.fire();
};
