import send from "../../assets/send.png";
import ViewCount from "../../components/ViewCount.js";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import WBSMenu from "./WBSMenu.js";
import { server } from "../../config/server.js";
import Swal from "sweetalert2";
export default function Whistleblower() {
  useEffect(() => {
    window.scrollTo(0, 0);
    getJenis()
  }, []);
  const [jenis,setJenis] =useState([])
  const navigate = useNavigate();
  const getJenis = () => {
    fetch(`${server}/api/v1/wbs/wbsViolation`, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      // mode: "cors", // no-cors, *cors, same-origin
      // body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then(async (res) => {
        console.log(res, "promox");

       setJenis(res.data)
      })

      .catch((err) => {
        console.log(err);
      });
  };
  // const formData = await new FormData();
  // await formData.append("image_file", imageToUpload);
  // // await formData.append("Thumbnail", thumbnail);

  // await formData.append(
  //   "title",
  //   document.getElementById("title_create_sliders").value
  // );
  const handleSubmit = async () => {
    // const data = {
    //   wbs_user_email: document.getElementById("email_login").value,
    //   password: document.getElementById("password_login").value,
    // };
    console.log(document.getElementById("image_file").value);

    const formData = await new FormData();
    // await formData.append("image_file", imageToUpload);
    // await formData.append("Thumbnail", thumbnail);

    await formData.append(
      "reported_name",
      document.getElementById("reported_name").value
    );

    await formData.append(
      "reported_position",
      document.getElementById("reported_position").value
    );

    await formData.append(
      "reported_place",
      document.getElementById("reported_place").value
    );

    await formData.append("violation_type_id",  document.getElementById("violation_type_id").value);

    await formData.append(
      "violation_place",
      document.getElementById("violation_place").value
    );

    await formData.append(
      "violation_date",
      document.getElementById("violation_date").value
    );

    await formData.append(
      "violation_time",
      document.getElementById("violation_time").value
    );

    await formData.append(
      "violation_chronology",
      document.getElementById("violation_chronology").value
    );
    await formData.append(
      "image_file",
      document.getElementById("image_file").value
    );

    console.log("masuk");
    fetch(`${server}/api/v1/wbs/wbs`, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      // mode: "cors", // no-cors, *cors, same-origin
      body: formData,
      headers: {
        // "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then(async (res) => {
        console.log(res, "promox");
        const Toast = Swal.mixin({
          // toast: true,
          // position: "top-end",
          showConfirmButton: true,
          // backdrop: false,
          // timer: 3000,
          // animation:false,
          backdrop: `transparent`,
          // timerProgressBar: true,
          allowOutsideClick: false,
          confirmButtonColor: `#005f3b`,
          confirmButtonFontFamily: "Roboto",
          didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
          },
        });
        if (!res.status) {
          Toast.fire({
            // icon: "error",

            title: res.data.message,
            text: res.data.desc,
          });
        } else if (res.status == true) {
          Toast.fire({
            // icon: "error",

            title: "Success",
            text: "Laporan berhasil dibuat",
          }).then((isConfirm) => {
            navigate("/whistleblower/status");
          });
        }
      })

      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      {/* <div
        class="pageMainImg"
        style={{ backgroundImage: `url(${whistleblowerMain})` }}
      ></div> */}
      {/* <div class="w100 text-center">
        <button
          class="btn mx-3 px-5 "
          style={{
            backgroundColor: "#005F3B",
            color: "white",
            borderRadius: "50rem",
            fontFamily: "Roboto",
            fontSize: 18,
          }}
        >
          Buat Pengaduan
        </button>
        <button
          class="btn mx-3 px-5 "
          style={{
            backgroundColor: "#EF582C",
            color: "white",
            borderRadius: "50rem",
            fontFamily: "Roboto",
            fontSize: 18,
          }}
        >
          Register
        </button>
        <button
          class="btn mx-3 px-5 greenText"
          style={{
            borderRadius: "50rem",
            fontFamily: "Roboto",
            fontSize: 18,
            border: "1px solid #005F3B",
          }}
        >
          Login
        </button>
      </div> */}{" "}
      <WBSMenu />
      <div class="pageTitle">Form Pengaduan Whistleblower</div>
      <div
        class=" pageText  mx-auto h100 w100 center-vertical justify-content-center"
        style={{}}
      >
        <form
          onSubmit={(e) => {
            console.log("infpo");
            e.preventDefault();
            handleSubmit();
          }}
        >
          <div class="w100">
            {/* <label style={{}} className="text-left mb-2 pageText">
            Nama Pelapor
          </label>
          <input
            style={{ border: "1px solid #005F3B wb_input" }}
            required
            pattern="[^' ']+"
            type="text"
            id="client_code"
            // value={name}
            // onChange={(e) => {
            //   setTitle(e.target.value);
            // }}
            class="form-control py-2  mb-3 addForm br10"
            aria-describedby="passwordHelpBlock"
            placeholder="Nama Pelapor"
          /> */}
            <div
              class="flex w100 "
              style={{ justifyContent: "space-between", gap: "25px" }}
            >
              <div class="w100">
                <label style={{}} className="text-left mb-2 pageText">
                  Nama Pelapor
                </label>
                <input
                  style={{
                    border: "1px solid #005F3B wb_input",
                  }}
                  type="text"
                  id="Nama Pelapor"
                  // value={attn}
                  // onChange={(e) => {
                  //   setTitle(e.target.value);
                  // }}
                  class="form-control py-2  mb-3 addForm br10"
                  aria-describedby="passwordHelpBlock"
                  placeholder="Email Pelapor"
                />
              </div>
              <div class="w100">
                <label style={{}} className="text-left mb-2 pageText">
                  Nomor Telepon Pelapor
                </label>
                <input
                  style={{
                    border: "1px solid #005F3B wb_input",
                  }}
                  type="text"
                  id="client_title"
                  // value={Title}
                  // onChange={(e) => {
                  //   setTitle(e.target.value);
                  // }}
                  class="form-control py-2  mb-3 addForm br10"
                  aria-describedby="passwordHelpBlock"
                  placeholder="Nomor Telepon Pelapor"
                />
              </div>
            </div>
            <div
              class="flex w100 "
              style={{ justifyContent: "space-between", gap: "25px" }}
            >
              <div class="w100">
                <label style={{}} className="text-left mb-2 pageText">
                  Provinsi
                </label>
                <input
                  style={{
                    border: "1px solid #005F3B wb_input",
                  }}
                  type="text"
                  id="Provinsi"
                  // value={attn}
                  // onChange={(e) => {
                  //   setTitle(e.target.value);
                  // }}
                  class="form-control py-2  mb-3 addForm br10"
                  aria-describedby="passwordHelpBlock"
                  placeholder="Provinsi"
                />
              </div>
              <div class="w100">
                <label style={{}} className="text-left mb-2 pageText">
                  Kabupaten/Kota
                </label>
                <input
                  style={{
                    border: "1px solid #005F3B wb_input",
                  }}
                  type="text"
                  id="client_title"
                  // value={Title}
                  // onChange={(e) => {
                  //   setTitle(e.target.value);
                  // }}
                  class="form-control py-2  mb-3 addForm br10"
                  aria-describedby="passwordHelpBlock"
                  placeholder="Kabupaten/Kota"
                />
              </div>
            </div>
            <label style={{}} className="text-left mb-2 pageText">
              Alamat Tempat Tugas
            </label>
            <input
              style={{ border: "1px solid #005F3B wb_input", height: 250 }}
              pattern="[^' ']+"
              type="text"
              id="reported_place"
              // value={name}
              // onChange={(e) => {
              //   setTitle(e.target.value);
              // }}
              class="form-control py-2  mb-3 addForm br10"
              aria-describedby="passwordHelpBlock"
            />
            <div
              class="flex w100 "
              style={{ justifyContent: "space-between", gap: "25px" }}
            >
              <div class="w100">
                <label style={{}} className="text-left mb-2 pageText">
                  Nama Lengkap Terlapor
                  <span style={{ color: "#e62c2c" }}>*</span>
                </label>
                <input
                  style={{
                    border: "1px solid #005F3B wb_input",
                  }}
                  required
                  type="text"
                  id="reported_name"
                  // value={attn}
                  // onChange={(e) => {
                  //   setTitle(e.target.value);
                  // }}
                  class="form-control py-2  mb-3 addForm br10"
                  aria-describedby="passwordHelpBlock"
                  placeholder="Nama Lengkap Terlapor"
                />
              </div>
              <div class="w100">
                <label style={{}} className="text-left mb-2 pageText">
                  Jabatan/Unit Kerja Terlapor
                  <span style={{ color: "#e62c2c" }}>*</span>
                </label>
                <input
                  style={{
                    border: "1px solid #005F3B wb_input",
                  }}
                  required
                  type="text"
                  id="reported_position"
                  // value={Title}
                  // onChange={(e) => {
                  //   setTitle(e.target.value);
                  // }}
                  class="form-control py-2  mb-3 addForm br10"
                  aria-describedby="passwordHelpBlock"
                  placeholder="Jabatan/Unit Kerja Terlapor"
                />
              </div>
            </div>
            <div
              class="flex w100 "
              style={{ justifyContent: "space-between", gap: "25px" }}
            >
              <div class="w100">
                <label style={{}} className="text-left mb-2 pageText">
                  Jenis Pelanggaran<span style={{ color: "#e62c2c" }}>*</span>
                </label>
                {/* <input
                  style={{
                    border: "1px solid #005F3B wb_input",
                  }}
                  required
                  type="text"
                  id="violation_type_id"
                  // value={attn}
                  // onChange={(e) => {
                  //   setTitle(e.target.value);
                  // }}
                  class="form-control py-2  mb-3 addForm br10"
                  aria-describedby="passwordHelpBlock"
                  placeholder="Jenis Pelanggaran"
                /> */}
                <select required  class="form-control form-control py-2  mb-3 addForm br10"id="violation_type_id">

                  {jenis.map((jenis,i)=>{
                    return (
                      <option value={jenis.violation_type_id}>{jenis.violation_type_name}</option>
                    )
                  })}
                  {/* <option value="">--Please choose an option--</option> */}
                  {/* <option value="dog">Dog</option>
                  <option value="cat">Cat</option>
                  <option value="hamster">Hamster</option>
                  <option value="parrot">Parrot</option>
                  <option value="spider">Spider</option>
                  <option value="goldfish">Goldfish</option> */}
                </select>
              </div>
              <div class="w100">
                <label style={{}} className="text-left mb-2 pageText">
                  Tempat Pelanggaran
                  <span style={{ color: "#e62c2c" }}>*</span>
                </label>
                <input
                  style={{
                    border: "1px solid #005F3B wb_input",
                  }}
                  required
                  type="text"
                  id="violation_place"
                  // value={Title}
                  // onChange={(e) => {
                  //   setTitle(e.target.value);
                  // }}
                  class="form-control py-2  mb-3 addForm br10"
                  aria-describedby="passwordHelpBlock"
                  placeholder="Tempat Pelanggaran"
                />
              </div>
            </div>
            <label style={{}} className="text-left mb-2 pageText">
              Kronologi Pelanggaran
              <span style={{ color: "#e62c2c" }}>*</span>
            </label>

            <input
              //  placeholder="Tempat/ Lokasi Pelanggaran"
              style={{ border: "1px solid #005F3B wb_input", height: 250 }}
              required
              pattern="[^' ']+"
              type="text"
              id="violation_chronology"
              // value={name}
              // onChange={(e) => {
              //   setTitle(e.target.value);
              // }}
              class="form-control py-2  mb-3 addForm br10"
              aria-describedby="passwordHelpBlock"
            />
            <div
              class="flex w100 "
              style={{ justifyContent: "space-between", gap: "25px" }}
            >
              <div class="w100">
                <label style={{}} className="text-left mb-2 pageText">
                  Tanggal Perkiraan Kejadian
                  <span style={{ color: "#e62c2c" }}>*</span>
                </label>
                <input
                  style={{
                    border: "1px solid #005F3B wb_input",
                  }}
                  required
                  type="date"
                  id="violation_date"
                  // value={attn}
                  // onChange={(e) => {
                  //   setTitle(e.target.value);
                  // }}
                  class="form-control py-2  mb-3 addForm br10"
                  aria-describedby="passwordHelpBlock"
                  placeholder="Tanggal Perkiraan Kejadian"
                />
              </div>
              <div class="w100">
                <label style={{}} className="text-left mb-2 pageText">
                  Waktu Perkiraan Kejadian<span style={{ color: "#e62c2c" }}>*</span>
                </label>
                <input
                  style={{
                    border: "1px solid #005F3B wb_input",
                  }}
                  type="time"
                  id="violation_time"
                  // value={Title}
                  // onChange={(e) => {
                  //   setTitle(e.target.value);
                  // }}
                  class="form-control py-2  mb-3 addForm br10"
                  aria-describedby="passwordHelpBlock"
                  placeholder="Waktu Perkiraan Kejadian"
                />
              </div>
            </div>
            <label style={{}} className="text-left mb-2 pageText">
              Lampiran Bukti Permulaan
            </label>
            <input
              style={{ border: "1px solid #005F3B wb_input" }}
              pattern="[^' ']+"
              type="file"
              id="image_file"
              multiple="multiple"
              // value={name}
              // onChange={(e) => {
              //   setTitle(e.target.value);
              // }}
              class="form-control   mb-3 addForm br10"
              aria-describedby="passwordHelpBlock"
              placeholder="lampiran Bukti Permulaan"
            />
            <div
              class="mb-2 w100  "
              style={{
                textAlign: "justify",
                backgroundColor: "#d1f5e7",
                borderRadius: 10,
                paddingTop: "1.4rem",
                paddingBottom: "3rem",
                paddingLeft: "7rem",
                paddingRight: "7rem",
              }}
            >
              {" "}
              <span style={{ fontSize: 18 }}>
                <p>
                  {" "}
                  <span
                    style={{
                      fontWeight: 900,
                      fontSize: "2rem",
                      fontStyle: "normal",
                      width: "28em",
                      height: "1em",
                      fontFamily: "Icons",
                      fontStyle: "normal",
                      display: "block",
                      textDecoration: "inherit",
                      lineHeight: 1,
                    }}
                  >
                    i
                  </span>
                  &nbsp;&nbsp;&nbsp;PT. Kawasan Berikat Nusantara (Persero)
                  menjamin kerahasiaan identitas Anda sebagai whistleblower
                  karena PT. Kawasan Berikat Nusantara (Persero) hanya fokus
                  pada informasi yang Anda laporkan.
                </p>

                <p>
                  Agar Kerahasiaan lebih terjaga, perhatikan hal-hal berikut
                  ini?
                </p>

                <ol>
                  <li>
                    Jika ingin identitas Anda tetap rahasia, jangan
                    memberitahukan/ mengisikan data-data pribadi, seperti nama
                    Anda, atau hubungan Anda dengan pelaku-pelaku.
                  </li>
                  <li>
                    Jangan memberitahukan/ mengisi data-data/ informasi yang
                    memungkinkan bagi orang lain untuk melakukan pelacakan siapa
                    Anda. Hindari orang lain mengetahui nama samaran (username)
                    Anda.
                  </li>
                  <li>
                    Dengan melakukan pengaduan melalui aplikasi KBN whistle
                    blowing system dengan ini pelapor menyetujui bahwa pelapor
                    bersedia untuk dikonfirmasi dan dimintai keterangan oleh
                    pihak/ petugas WBS Kawasan Berikat Nusantara terkait tindak
                    lanjut pengaduan yang dilakukan oleh pelapor.
                  </li>
                </ol>
              </span>
            </div>
            <button
              onClick={(e) => {
                // console.log("infpo");
                // e.preventDefault();
                // navigate("/whistleblower");
              }}
              class="  px-4 flex center-vertical"
              style={{
                height: 54,
                maxWidth: 354,
                backgroundColor: "#005F3B",
                fontFamily: "Roboto",
                fontWeight: 400,
                fontSize: 20,
                color: "white",
                border: "0px solid transparent",
                borderRadius: 10,
                gap: 70,
                marginTop: "2rem",
              }}
            >
              <div>Submit Laporan</div>
              <div>
                <img src={send} style={{ width: "1.5rem" }}></img>
              </div>
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
