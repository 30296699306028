import React from "react";
import googleGreen from "../assets/googleGreen.png";
import twitterGreen from "../assets/twitterGreen.png";
import fbGreen from "../assets/fbGreen.png";

export default function ViewCount({ count = 69 }) {

  const handleTwitterClick = () => {
    window.open("http://twitter.com/share?url=http://kbn.co.id/welcome/konten_depan/view/8&text=Berita_KBN%20Share%20Buttons&hashtags=KBN", "_blank");
  };

  const handleFacebookClick = () => {
    window.open("http://www.facebook.com/sharer.php?u=http://kbn.co.id/welcome/konten_depan/view/8", "_blank");
  };

  return (
    <div className="w100" style={{ color: "#1E1E1E", fontSize: 20, fontFamily: "Roboto", fontWeight: 400, wordWrap: "break-word", marginTop: "5rem" }}>
      <div>Telah Dikunjungi Sebanyak: {count} Kali</div>
      <div className="flex mt-2">
        <div>Share:</div>
      
        <div className="shareSosmed mx-2" onClick={handleTwitterClick}>
          <img className="w100" style={{maxWidth:28,maxHeight:28}} src={twitterGreen} alt="Twitter" />
        </div>
        <div className="shareSosmed mx-2" onClick={handleFacebookClick}>
          <img className="w100" style={{maxWidth:28,maxHeight:28}} src={fbGreen} alt="Facebook" />
        </div>
      </div>
    </div>
  );
}
