import logistikMain from "../assets/logistikMain.png";
import ViewCount from "../components/ViewCount.js";
import React, { useEffect } from "react";
export default function Logistik() {
  useEffect(() => {
    console.log("dsd");
    window.scrollTo(0, 0);
  }, []);
  return (
    <div class="py-5 upperHomeContainer mx-auto" style={{ minHeight: "100vh" }}>
      <>
        <div class="pageMainImg text-center center-vertical">
          <div class="pageTitle mx-auto">Coming Soon</div>
        </div>
        {/* <div class="pageTitle">pusat logistik berikat</div>
<div class="articleContainer pageText">
  <p>
    Pusat Logistik Berikat (PLB) adalah tempat penimbunan barang asal luar
    daerah Pabean dan/atau barang yang berasal dari tempat lain dalam
    daerah Pabean dalam jangka waktu tertentu, serta dapat disertai satu
    atau lebih kegiatan sederhana.
  </p>
  <br></br>
  <p>
    <span>
      Keunggulan PLB
      <ul>
        <li>Penangguhan bea masuk dan pajak saat barang masuk</li>
        <li>Fleksibilitas masa timbun barang hingga 3 tahun*)</li>
        <li>
          Fleksibilitas kepemilikan barang, kecepatan layanan berbasis IT
          dan warehouse Management System
        </li>
        <li>
          Nilai Pabean digunakan saat pengeluaran barang dari PLB PT KBN
          (Persero)
        </li>
      </ul>
    </span>
  </p>
  <br></br>
  <p>
    Pelayanan yang ada pada PLB yaitu:
    <ul>
      <li>Stripping & Struffing</li>
      <li>Warehousing</li>
      <li>Inventory Control</li>
      <li>Trucking</li>
      <li>24 Hours Security</li>
    </ul>
  </p>
  <ViewCount count={77}/>
</div> */}
      </>
    </div>
  );
}
