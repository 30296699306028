import logistikMain from "../../assets/logistikMain.png";
import prop1 from "../../assets/prop1.jpg";
import prop2 from "../../assets/prop2.jpg";
import prop3 from "../../assets/prop3.jpg";
import prop4 from "../../assets/prop4.jpg";
import ViewCount from "../../components/ViewCount.js";
import React, { useEffect, useState } from "react";
import "react-image-lightbox/style.css";
import Lightbox from "yet-another-react-lightbox";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import { useNavigate } from "react-router-dom";

import DataTable from "react-data-table-component";
export default function Properti() {
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const images = [
    { src: prop1 },
    { src: prop2 },
    { src: prop3 },
    { src: prop4 },
  ];
  const columns = [
    {
      name: <div>Hari/Tanggal</div>,
      selector: (row, i) => row.date,
      sortable: true,
      
      // width: "100px",
      // style:{
      //   backgroundColor:'red',
      //   textAlign:'center',
      //   width:"100%"
      // },
      
    },
    {
      name: <div>Media</div>,
      selector: (row, i) => row.media,
      sortable: true,
      
      // width: "100px",
      // style:{
      //   backgroundColor:'red',
      //   textAlign:'center',
      //   width:"100%"
      // },
      
    },
    {
      name: <div>Judul Berita</div>,
      selector: (row, i) => row.title,
      sortable: true,
      
      // width: "100px",
      // style:{
      //   backgroundColor:'red',
      //   textAlign:'center',
      //   width:"100%"
      // },
      
    },
    {
      name: <div>Keterangan</div>,
      selector: (row, i) => row.ket,
      sortable: true,
      
      // width: "100px",
      // style:{
      //   backgroundColor:'red',
      //   textAlign:'center',
      //   width:"100%"
      // },
      
    },
    





  ];
  const handleLighbox = async (i) => {
    await console.log(i);
    await setSelectedImage(i);
    await console.log(selectedImage);
    setLightboxOpen(true);
  };
const data =[{
  media :'https://money.kompas.com/read/2020/10/21/124620426/pansus-kbn-tinjau-pelabuhan-marunda-kcn-menunggu-rekomendasi-terbaik',
  title :'Bukit Asam, Kawasan Berikat Nusantara, Brantas Abipraya Selenggarakan BUMN untuk Negeri',
  id:0,
  date:'28 Januari 2021, Kamis 04:03'
  ,ket:'Media Online'
},
{
  media :'https://money.kompas.com/read/2020/10/21/124620426/pansus-kbn-tinjau-pelabuhan-marunda-kcn-menunggu-rekomendasi-terbaik',
  title :'Bukit Asam, Kawasan Berikat Nusantara, Brantas Abipraya Selenggarakan BUMN untuk Negeri',
  id:1,
  date:'28 Januari 2021, Kamis 04:03'
  ,ket:'Media Online'
},
{
  media :'https://money.kompas.com/read/2020/10/21/124620426/pansus-kbn-tinjau-pelabuhan-marunda-kcn-menunggu-rekomendasi-terbaik',
  title :'Bukit Asam, Kawasan Berikat Nusantara, Brantas Abipraya Selenggarakan BUMN untuk Negeri',
  id:2,
  date:'28 Januari 2021, Kamis 04:03'
  ,ket:'Media Online'
},
{
  media :'https://money.kompas.com/read/2020/10/21/124620426/pansus-kbn-tinjau-pelabuhan-marunda-kcn-menunggu-rekomendasi-terbaik',
  title :'Bukit Asam, Kawasan Berikat Nusantara, Brantas Abipraya Selenggarakan BUMN untuk Negeri',
  id:3,
  date:'28 Januari 2021, Kamis 04:03'
  ,ket:'Media Online'
},
{
  media :'https://money.kompas.com/read/2020/10/21/124620426/pansus-kbn-tinjau-pelabuhan-marunda-kcn-menunggu-rekomendasi-terbaik',
  title :'Bukit Asam, Kawasan Berikat Nusantara, Brantas Abipraya Selenggarakan BUMN untuk Negeri',
  id:4,
  date:'28 Januari 2021, Kamis 04:03'
  ,ket:'Media Online'
},

]
  return (
    <>
      <Lightbox
        open={lightboxOpen}
        close={() => setLightboxOpen(false)}
        slides={images}
        plugins={[Thumbnails]}
        index={selectedImage}
      />
      <div
        class="pageMainImg"
        style={{ backgroundImage: `url(${logistikMain})` }}
      ></div>
      <div class="pageTitle">Kliping</div>
      <div class="articleContainer pageText">
        <div class="list image">
        <DataTable
        pointerOnHover={true}
        highlightOnHover={true}
        onRowClicked={(e) => {
          navigate(`/media/kliping/${e.id}`)
        }}
        // title="Kliping"
        columns={columns}
        data={data}
        pagination
        striped
        // customStyles={customStyles}
        // paginationComponentOptions={paginationComponentOptions}
        paginationRowsPerPageOptions={[10, 25, 50, 100]}
        // subHeader
        // subHeaderComponent={subHeaderComponentMemo}
        // selectableRows
        // contextActions={contextActions}
        // onSelectedRowsChange={handleRowSelected}
        // clearSelectedRows={toggleCleared}
        // onSelectedRowsChange={handleSelected}
        // persistTableHead
        // onChangeRowsPerPage={(e) => {
        //   handlePerRowsChange(e);
        // }}

        // actions={actionsMemo}
      />
         
        </div>
      </div>
    </>
  );
}
