import whistleblowerMain from "../../assets/whistleblowerMain.png";
import ViewCount from "../../components/ViewCount.js";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import WBSMenu from "./WBSMenu.js";
import Swal from "sweetalert2";
import { server } from "../../config/server.js";
import moment from "moment";
export default function Whistleblower() {
  const [isLogin, setIsLogin] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    getData();
    window.scrollTo(0, 0);
  }, []);
  const [data, setData] = useState([]);

  const getData = () => {
    fetch(`${server}/api/v1/wbs/wbs`, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      // mode: "cors", // no-cors, *cors, same-origin
      // body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then(async (res) => {
        console.log(res, "promox");

        if (!res.status) {
          const Toast = Swal.mixin({
            // toast: true,
            // position: "top-end",
            showConfirmButton: true,
            // backdrop: false,
            // timer: 3000,
            // animation:false,
            backdrop: `transparent`,
            // timerProgressBar: true,
            allowOutsideClick: false,
            confirmButtonColor: `#005f3b`,
            confirmButtonFontFamily: "Roboto",
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            },
          });
          Toast.fire({
            // icon: "error",

            title: "Error",
            text: "Username atau password salah",
          });
        } else if (res.status == true) {
          setData(res.data);
        }
      })

      .catch((err) => {
        console.log(err);
      });
  };
  const status = (s) => {
    console.log("setatus", s);
    switch (s) {
      case 1:
        return "Diterima";
      case 2:
        return "Diproses";
      case 3:
        return "Selesai";
      case 4:
        return "Batal";

      default:
        break;
    }
  };
  //172.16.110.204
  return (
    <>
      {/* <div
        class="pageMainImg"
        style={{ backgroundImage: `url(${whistleblowerMain})` }}
      ></div> */}
      <WBSMenu />
      {/* <div class="pageTitle">Whistleblower</div> */}

      <div
        class=" pageText mx-auto mt-5 h100 center-vertical justify-content-center"
        style={{}}
      >
        <div class="w100">
          <div class="pengumumanTabelWaran">
            {" "}
            <div class="w90 mx-auto mb-5 pb-5 " style={{ overflow: "auto" }}>
              <table
                id="searchForm"
                className="table  table-striped table-warrant table-pengumuman table-pengumuman "
                style={{
                  textAlign: "center",
                  fontSize: 12,
                }}
              >
                <thead style={{}}>
                  <tr style={{}}>
                    <th
                      className="py-4 white text-center mx-auto  blue-border monsterratMedium  fixedTableCol"
                      style={{
                        backgroundColor: "#005f3b",
                        color: "white",
                        fontFamily: "Roboto",
                        fontWeight: 500,
                        width: "15%",
                        borderTopLeftRadius: 20,
                      }}
                    >
                      Tanggal Pelaporan
                    </th>{" "}
                    <th
                      className="py-4 white text-center mx-auto  blue-border monsterratMedium  fixedTableCol"
                      style={{
                        backgroundColor: "#005f3b",
                        color: "white",
                        fontFamily: "Roboto",
                        fontWeight: 500,
                        width: "25%",
                        borderLeft: "1px solid white",
                        borderRight: "1px solid white",
                      }}
                    >
                      Terlapor
                    </th>{" "}
                    <th
                      className="py-4 white text-center mx-auto  blue-border monsterratMedium  fixedTableCol"
                      style={{
                        backgroundColor: "#005f3b",
                        color: "white",
                        fontFamily: "Roboto",
                        fontWeight: 500,
                        width: "50%",
                        borderLeft: "1px solid white",
                        borderRight: "1px solid white",
                      }}
                    >
                      Perihal
                    </th>{" "}
                    <th
                      className="py-4 white text-center mx-auto  th_waran blue-border monsterratMedium  fixedTableCol"
                      style={{
                        backgroundColor: "#005f3b",
                        color: "white",
                        fontFamily: "Roboto",
                        fontWeight: 500,
                        width: "15%",
                        borderTopRightRadius: 20,
                        fontSize: 12,
                      }}
                    >
                      Status
                    </th>{" "}
                  </tr>
                </thead>
                <tbody id="formData " style={{ fontSize: 12 }}>
                  {data.map((data, i) => {
                    return (
                      <tr className="form_c">
                        <td
                          className=" monsterratMedium py-2 search-td-h noLeftBorder blue-text-1 fixedTableCol "
                          style={{
                            fontFamily: "Roboto",
                            borderRight: "1px solid transparent ",
                            borderLeft: "1px solid white ",
                            color: "black",
                          }}
                        >
                          {moment(data.createddate).format("DD-MMM-YYYY")}
                          {data.violation_name}
                        </td>
                        <td
                          className="  monsterratMedium py-2 tdRhb noLeftBorder text-left px-5 "
                          style={{ fontFamily: "Roboto" }}
                        >
                          {" "}
                          {data.reported_name}
                        </td>
                        <td
                          className="  monsterratMedium py-2 tdRhb noLeftBorder text-left px-5 "
                          style={{ fontFamily: "Roboto" }}
                        >
                          {" "}
                          {data.violation_type_name}
                        </td>
                        <td
                          className=" monsterratMedium py-2 c_one_1 tdRhb text-center"
                          style={{
                            fontFamily: "Roboto",
                            borderLeft: "1px solid white",
                          }}
                        >
                          {status(data.wbs_status_id)}
                        </td>

                        {/* Repeat the same structure for other table data cells */}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
