import logistikMain from "../../assets/logistikMain.png";
import prop1 from "../../assets/prop1.jpg";
import prop2 from "../../assets/prop2.jpg";
import prop3 from "../../assets/prop3.jpg";
import prop4 from "../../assets/prop4.jpg";
import ViewCount from "../../components/ViewCount.js";
import React, { useEffect, useState } from "react";
import moment from "moment";
import "react-image-lightbox/style.css";
import Lightbox from "yet-another-react-lightbox";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { server } from "../../config/server.js";
export default function BeritaById() {
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);
  const [selectedAlbum, setSelectedAlbum] = useState();
  const navigate = useNavigate();
  const location = useLocation();
const [news,setNews] = useState({})
  // const news = location.state.news;
  const { id } = useParams();
  const album = [
    { src: prop1 },
    { src: prop2 },
    { src: prop3 },
    { src: prop1 },
    { src: prop2 },
    { src: prop3 },
    { src: prop1 },
    { src: prop2 },
    { src: prop3 },
    { src: prop1 },
    { src: prop2 },
    { src: prop3 },
  ];
  const getNews = () => {
    fetch(`${server}/api/v1/news/view?news_id=${id}`, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      // mode: "cors", // no-cors, *cors, same-origin
      // body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then(async (res) => {
        console.log(res, "promox");
if(res.status){

  setNews(res.data[0]);
}else{
  throw res
}
      })

      .catch((err) => {
        console.log(err);
      });
  };
  const MySwal = withReactContent(Swal);
  useEffect(() => {
    console.log("babay", id);
    getNews()
    window.scrollTo(0, 0);
  }, []);

  const images = [
    { src: prop1 },
    { src: prop2 },
    { src: prop3 },
    { src: prop4 },
  ];

 

  const handleLighbox = async (i) => {
    await console.log(i);
    await setSelectedImage(i);
    await console.log(selectedImage);
    setLightboxOpen(true);
  };

  return (
    <>
      {/* {JSON.stringify(news)} */}
      <Lightbox
        open={lightboxOpen}
        close={() => setLightboxOpen(false)}
        slides={news.images}
        plugins={[Thumbnails]}
        index={1}
      />
      {news.images ? (
        <div
          class="pageMainImg"
          style={{ backgroundImage: `url('${news.images[0].public_link}')` }}
        ></div>
      ) : null}

      <div
        class="pageTitle"
        style={{
          fontSize: 34,
          textAlign: "center",
        }}
      >
        {news.news_title}
      </div>
      <div class="articleContainer pageText">
        <div class="">
        <p style={{fontSize:18}}>{moment(news.news_entry_date).locale("id").format("DD MMM YYYY")}</p>
          <div class="w100 " dangerouslySetInnerHTML={{ __html:   news.content }}>
          
          </div>
        </div>
      </div>
    </>
  );
}
const galleryModal = (selectedAlbum, setLightboxOpen, setSelectedImage) => {
  const MySwal = withReactContent(Swal);
  let modal = MySwal.mixin({
    // toast: true,
    // icon:'success',
    // backdrop: false,
    width: "fit-content",
    height: "fit-content",
    // backdrop: false,
    customClass: "galleryModal",
    className: "galleryModal",
    allowOutsideClick: false,
    showClass: {
      backdrop: "swal2-noanimation", // disable backdrop animation
      popup: "animated fadeInDown faster",
      icon: "animated heartBeat delay-1s",
    },
    html: (
      <div>
        <div
          class="w100 mb-3 modalTitle pageText"
          style={{
            fontWeight: 600,
            color: "#005f3b",
          }}
        >
          {selectedAlbum.name}
        </div>
        <div
          style={{
            minHeight: "50vh",
          }}
          class="center-vertical mx-auto text-center"
        >
          {selectedAlbum.items.map((img, i) => {
            return (
              <img
                onClick={(e) => {
                  setLightboxOpen(true);
                  setSelectedImage(i);
                }}
                class="galleryImg px-2 py-2"
                src={img.src}
              ></img>
            );
          })}
        </div>
        <button
          class="btn mx-3 px-5"
          style={{
            borderRadius: "50rem",
            fontFamily: "Roboto",
            fontSize: 18,
            border: "1px solid #005F3B",
            background: "#005F3B",
            color: "white",
          }}
          onClick={(e) => {
            MySwal.close();
          }}
        >
          Back
        </button>
      </div>
    ),
    showConfirmButton: false,
    heightAuto: false,
  });
  return modal.fire();
};
